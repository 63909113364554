import { useEffect, useState } from "react";
import {
  getSetting,
  setSetting,
  updateSetting,
} from "../../@api/service/setting/setting";
import { useStoreStore } from "../../store/storeStore";
import { parseJsonText } from "typescript";
import { json } from "stream/consumers";
import axios from "axios";
interface BaudRate {
  baudRate1?: string | null;
  baudRate2?: string | null;
  baudRate3?: string | null;
  baudRate4?: string | null;
}
export const Setting = () => {
  const [port, setPort] = useState({
    deviceId: "",
    deviceId2: "",
    deviceId3: "",
    deviceId4: "",
  });
  const [baudRate, setBaudRate] = useState({
    deviceId: "",
    deviceId2: "",
    deviceId3: "",
    deviceId4: "",
  });
  const handlePortChange = (key, value) => {
    setPort((prev) => ({ ...prev, [key]: value }));
  };

  const handleBaudRateChange = (key, value) => {
    setBaudRate((prev) => ({ ...prev, [key]: value }));
  };
  const [tempVoiceCnt, setTempVoiceCnt] = useState(1);
  const [tempVolume, setTempVolume] = useState(1);
  const { storeId, setStoreId } = useStoreStore();
  const {
    storePort,
    setStorePort2,
    setStorePort,
    setStorePort3,
    setStorePort4,
    setStoreBaud,
    setStoreBaud2,
    setStoreBaud3,
    setStoreBaud4,
    storeBaud,
    setVoiceCnt,
    voiceCnt,
    volume,
    setVolume,
  } = useStoreStore();

  const getSettingApi = async () => {
    try {
      const result = await getSetting(storeId);

      console.log(result.data);
      setBaudRate({
        deviceId: result.data[0].baudRate,
        deviceId2: result.data[1].baudRate,
        deviceId3: result.data[2].baudRate,
        deviceId4: result.data[3].baudRate,
      });
      setPort({
        deviceId: result.data[0].devicePort,
        deviceId2: result.data[1].devicePort,
        deviceId3: result.data[2].devicePort,
        deviceId4: result.data[3].devicePort,
      });
      setTempVoiceCnt(result.data[0].soundCount);
      setTempVolume(result.data[0].volumeSound);
    } catch (error) {
      console.log(error);
    }
  };

  const setSettingApi = async () => {
    const data = [
      {
        storeId: storeId,
        baudRate: baudRate.deviceId,
        devicePort: port.deviceId,
        soundCount: tempVoiceCnt,
        deviceId: 1,
        isDeleted: baudRate.deviceId !== "" && port.deviceId !== "" ? "N" : "Y",
        volumeSound: tempVolume,
      },
      {
        storeId: storeId,
        baudRate: baudRate.deviceId2,
        devicePort: port.deviceId2,
        soundCount: tempVoiceCnt,
        deviceId: 2,
        isDeleted:
          baudRate.deviceId2 !== "" && port.deviceId2 !== "" ? "N" : "Y",
        volumeSound: tempVolume,
      },
      {
        storeId: storeId,
        baudRate: baudRate.deviceId3,
        devicePort: port.deviceId3,
        soundCount: tempVoiceCnt,
        deviceId: 3,
        isDeleted:
          baudRate.deviceId3 !== "" && port.deviceId3 !== "" ? "N" : "Y",
        volumeSound: tempVolume,
      },

      {
        storeId: storeId,
        baudRate: baudRate.deviceId4,
        devicePort: port.deviceId4,
        soundCount: tempVoiceCnt,
        deviceId: 4,
        isDeleted:
          baudRate.deviceId4 !== "" && port.deviceId4 !== "" ? "N" : "Y",
        volumeSound: tempVolume,
      },
    ];
    try {
      const result = await setSetting(data);
      console.log("set setting", result.data);

      getSettingApi();
      if (result.message === "성공") {
        setStorePort(port.deviceId);
        setStorePort2(port.deviceId2);
        setStorePort3(port.deviceId3);
        setStorePort4(port.deviceId4);
        setStoreBaud(baudRate.deviceId);
        setStoreBaud2(baudRate.deviceId2);
        setStoreBaud3(baudRate.deviceId3);
        setStoreBaud4(baudRate.deviceId4);
        setVoiceCnt(tempVoiceCnt);
        setVolume(tempVolume);
        alert("저장 되었습니다.");
      }
    } catch (error) {
      alert("저장 실패했습니다.");
      console.log(error);
    }
  };

  const printData = async (
    order,
    port = storePort,
    baudRate = storeBaud,
    cut = true,
    type: string
  ) => {
    try {
      const response = await axios.post("http://localhost:5000/print", {
        port: port,
        baudRate: baudRate,
        data: order, // order 정보를 API에 포함
        cut: cut, // 커팅 여부
        type: type,
      });
      console.log(response.data); // 서버 응답 출력
    } catch (error) {
      console.error("Error printing:", error);
    }
  };

  const usbOrderPrint = async (order, type) => {
    const data = {
      order: order,
      type: type,
    };
    try {
      const response = await axios.post("http://localhost:5001/print", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Print success:", response.data);
    } catch (error) {
      console.error("Error printing order:", error);
    }
  };

  const keys = ["deviceId", "deviceId2", "deviceId3", "deviceId4"];
  useEffect(() => {
    getSettingApi();
  }, []);

  return (
    <div className="flex flex-col pt-8 pl-4 pr-4 w-full">
      <div className="flex flex-col gap-4">
        <div className="text-2xl" style={{ fontFamily: "SUIT-Bold" }}>
          설정
        </div>
        <div className="flex flex-col gap-4 pt-4">
          <div className="text-sm" style={{ fontFamily: "SUIT-Medium" }}>
            라벨
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-1">
              <div className="flex flex-col gap-1">
                {keys.map((key, index) => (
                  <div key={key} className="flex flex-row gap-2 items-center">
                    <div>{index + 1}</div>
                    <select
                      value={port[key] || ""}
                      onChange={(e) => handlePortChange(key, e.target.value)}
                      className="w-[291px] pt-4 pl-2 pr-2 pb-4 text-base"
                      style={{
                        border: "1px solid #B6B6B6",
                        fontFamily: "SUIT-Medium",
                      }}
                    >
                      <option value="">포트 설정</option>
                      <>
                        {Array.from({ length: 20 }, (_, i) => (
                          <option key={i + 1} value={`COM${i + 1}`}>
                            {`COM${i + 1}`}
                          </option>
                        ))}
                        <option key={21} value="USB">
                          USB
                        </option>
                      </>
                    </select>
                    <select
                      value={baudRate[key] || ""}
                      onChange={(e) =>
                        handleBaudRateChange(key, e.target.value)
                      }
                      className="w-[291px] pt-4 pl-2 pr-2 pb-4 text-base"
                      style={{
                        border: "1px solid #B6B6B6",
                        fontFamily: "SUIT-Medium",
                      }}
                    >
                      <option value="">속도 설정</option>
                      <option value="9600">9600</option>
                      <option value="13200">13200</option>
                      <option value="19200">19200</option>
                      <option value="38400">38400</option>
                      <option value="57600">57600</option>
                      <option value="115200">115200</option>
                      <option value="USB">USB</option>
                    </select>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={() => {
                setSettingApi();
              }}
              className="p-4 w-fit h-fit text-base text-white"
              style={{ backgroundColor: "black", fontFamily: "SUIT-ExtraBold" }}
            >
              저장
            </button>
            <button
              onClick={() => {
                if (port.deviceId !== "USB") {
                  printData(
                    {
                      orderId: 0,
                      hotelId: 0,
                      hotelName: "",
                      hotelAddress: "",
                      hotelPhone: "",
                      storeId: 0,
                      storeName: "",
                      storePhone: "",
                      storeAddress: "",
                      roomNum: "",
                      userName: "",
                      userPhone: "",
                      deliveryAddress: "",
                      deliveryDetailAddress: "",
                      orderStatus: "",
                      orderItemList: [], // 빈 배열로 초기화
                      orderAmount: 0,
                      discountAmount: 0,
                      deliveryTip: 0,
                      payAmount: 0,
                      payMethod: "",
                      cardName: "",
                      cardNumber: "",
                      payAuthNo: "",
                      payInfo: "",
                      deliveryFee: "",
                      orderMemo: "",
                      deliveryMemo: "",
                      orderDate: "",
                      reservationDate: "",
                      reservationList: "",
                      acceptDate: "",
                      readyTime: 0,
                      estimateTime: "",
                      cancelDate: "",
                      deliveryStartDate: "",
                      deliveryEndDate: "",
                      cancelInfo: "",
                      isPrint: "",
                      orderType: "",
                      typeKey: "",
                      cretDt: "",
                      uptDt: "",
                      updateDate: "",
                      cancelFormatDate: "",
                      expectedPickupTime: "",
                      rowNum: 0,
                      mainItemName: "",
                      itemCount: 0,
                      orderStatusNm: "",
                      expectedPickupTimeR: "",
                    },
                    storePort,
                    storeBaud,
                    true,
                    "test"
                  );
                } else {
                  usbOrderPrint(
                    {
                      orderId: 0,
                      hotelId: 0,
                      hotelName: "",
                      hotelAddress: "",
                      hotelPhone: "",
                      storeId: 0,
                      storeName: "",
                      storePhone: "",
                      storeAddress: "",
                      roomNum: "",
                      userName: "",
                      userPhone: "",
                      deliveryAddress: "",
                      deliveryDetailAddress: "",
                      orderStatus: "",
                      orderItemList: [], // 빈 배열로 초기화
                      orderAmount: 0,
                      discountAmount: 0,
                      deliveryTip: 0,
                      payAmount: 0,
                      payMethod: "",
                      cardName: "",
                      cardNumber: "",
                      payAuthNo: "",
                      payInfo: "",
                      deliveryFee: "",
                      orderMemo: "",
                      deliveryMemo: "",
                      orderDate: "",
                      reservationDate: "",
                      reservationList: "",
                      acceptDate: "",
                      readyTime: 0,
                      estimateTime: "",
                      cancelDate: "",
                      deliveryStartDate: "",
                      deliveryEndDate: "",
                      cancelInfo: "",
                      isPrint: "",
                      orderType: "",
                      typeKey: "",
                      cretDt: "",
                      uptDt: "",
                      updateDate: "",
                      cancelFormatDate: "",
                      expectedPickupTime: "",
                      rowNum: 0,
                      mainItemName: "",
                      itemCount: 0,
                      orderStatusNm: "",
                      expectedPickupTimeR: "",
                    },
                    "test"
                  );
                }
              }}
              className="p-4 w-fit h-fit text-base text-black"
              style={{
                backgroundColor: "white",
                fontFamily: "SUIT-ExtraBold",
                border: "1px solid #000000",
              }}
            >
              영수증 데모 인쇄
            </button>
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-2 items-center justify-center">
              <div className="flex flex-row gap-2">
                <div className="w-[100px] flex justify-center items-center">
                  <div
                    className="text-center"
                    style={{ fontFamily: "SUIT-Medium" }}
                  >
                    음성 반복 횟수
                  </div>
                </div>
                <select
                  value={tempVoiceCnt}
                  onChange={(e) => {
                    setTempVoiceCnt(Number(e.target.value));
                  }}
                  className="w-[291px] pt-4 pl-2 pr-2 pb-4  text-base"
                  style={{
                    border: "1px solid #B6B6B6",
                    fontFamily: "SUIT-Medium",
                  }}
                >
                  <option disabled>주문 목소리를 선택해 주세요.</option>

                  {Array.from({ length: 20 }, (_, i) => (
                    <option key={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex justify-center items-center">
                  <div
                    className="text-center w-[100px]"
                    style={{ fontFamily: "SUIT-Medium" }}
                  >
                    볼륨 조절
                  </div>
                </div>
                <select
                  value={tempVolume}
                  onChange={(e) => {
                    setTempVolume(Number(e.target.value));
                  }}
                  className="w-[291px] pt-4 pl-2 pr-2 pb-4  text-base"
                  style={{
                    border: "1px solid #B6B6B6",
                    fontFamily: "SUIT-Medium",
                  }}
                >
                  <option disabled>볼륨을 선택해 주세요.</option>

                  {Array.from({ length: 10 }, (_, i) => (
                    <option key={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            </div>
            <button
              className="p-4 w-fit h-fit text-base text-white"
              style={{
                backgroundColor: "black",
                fontFamily: "SUIT-ExtraBold",
              }}
              onClick={() => {
                const playSound = async () => {
                  for (let i = 0; i < tempVoiceCnt; i++) {
                    const audio = new Audio("/sounds/orderSound.mp3");
                    audio.volume = tempVolume / 10;
                    await audio.play();
                    // 소리가 끝날 때까지 대기
                    await new Promise((resolve) => {
                      audio.onended = resolve;
                    });
                  }
                };
                playSound();
              }}
            >
              주문 목소리 재생
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
