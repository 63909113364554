import { useState } from "react";
import { OrderType } from "../../../types/orderType";

export const Table = ({
  orderList,
  setItemIndex,
  itemIndex,
}: {
  orderList: OrderType[];
  setItemIndex: (itemIndex: number) => void;
  itemIndex: number;
}) => {
  const ContentCol = ({
    content,
    width,
    side,
    first,
    index,
  }: {
    content: string;
    width: number;
    side: string;
    first?: boolean;
    index: number;
  }) => (
    <div
      className="flex justify-center items-center pb-4 pt-4 pl-1 pr-1 cursor-pointer"
      onClick={() => {
        setItemIndex(index);
      }}
      style={{
        width: `${width}%`,
        borderLeft: first ? "1px solid #E3E3E3" : "",
        borderRight: "1px solid #E3E3E3",
        backgroundColor: index === itemIndex ? "#B1CED44D" : "",
      }}
    >
      <div
        className="text-center text-xs"
        style={{ fontFamily: "SUIT-Medium" }}
      >
        {content}
      </div>
    </div>
  );

  const Col = ({
    title,
    width,
    side,
  }: {
    title: string;
    width: number;
    side: string;
  }) => (
    <div
      className="flex justify-center items-center pb-4 pt-4"
      style={{
        width: `${width}%`,
        backgroundColor: "#2C3832",
        borderRight: "1px solid #E3E3E3",
        borderTopLeftRadius: side === "left" ? "8px" : "",
        borderTopRightRadius: side === "right" ? "8px" : "",
      }}
    >
      <div
        className="text-xs"
        style={{ fontFamily: "SUIT-Bold", color: "#FFFFFF" }}
      >
        {title}
      </div>
    </div>
  );

  const orderStatus = (status: string) => {
    if (status === "00") {
      return "주문 접수";
    } else if (status === "10") {
      return "주문 접수";
    } else if (status === "11") {
      return "배달사 취소";
    } else if (status === "20") {
      return "주문 취소";
    } else if (status === "25") {
      return "영업 시간 외 취소";
    } else if (status === "30") {
      return "배달 요청";
    } else if (status === "32") {
      return "기사 배정";
    } else if (status === "53") {
      return "배달 접수";
    } else if (status === "80") {
      return "환불";
    } else if (
      status === "90" ||
      status === "91" ||
      status === "92" ||
      status === "93" ||
      status === "95" ||
      status === "96"
    ) {
      return "완료";
    } else if (status === "94") {
      return "배달 완료";
    } else {
      return "status 오류";
    }
  };

  return (
    <div
      className="flex flex-col"
      style={{
        height: "700px",
        overflowY: "auto",
        border: "1px solid #E3E3E3E3E3E3",
        borderRadius: "8px",
        width: "60%",
      }}
    >
      <div
        className="flex flex-row w-full"
        style={{
          borderRadius: "8px",
          borderBottom: "1px solid #E3E3E3",
        }}
      >
        <Col side="left" title="번호" width={10} />
        <Col side="center" title="호텔" width={20} />
        <Col side="center" title="방번호" width={15} />
        <Col side="center" title="상태" width={15} />
        <Col side="center" title="주문목록" width={20} />
        <Col side="right" title="주문금액" width={20} />
      </div>
      <div
        className="flex flex-col w-full "
        style={{
          borderRadius: "8px",
          // borderBottom: "1px solid #E3E3E3",
          maxHeight: "720px", // 최대 높이 설정, 필요에 따라 조정
        }}
      >
        {orderList.length > 0 &&
          orderList.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-row "
                style={{ borderBottom: "1px solid #E3E3E3" }}
              >
                {" "}
                <ContentCol
                  first
                  side="left"
                  content={String(index + 1)}
                  width={10}
                  index={index}
                />
                <ContentCol
                  side="center"
                  content={item.hotelName}
                  width={20}
                  index={index}
                />
                <ContentCol
                  side="center"
                  content={item.roomNum}
                  width={15}
                  index={index}
                />
                <ContentCol
                  index={index}
                  side="center"
                  content={orderStatus(item.orderStatus)}
                  width={15}
                />
                <ContentCol
                  index={index}
                  side="center"
                  content={
                    item.orderItemList.length > 1
                      ? item.orderItemList[0]?.itemName +
                        " 외 " +
                        String(item.orderItemList.length - 1)
                      : item.orderItemList[0]?.itemName
                  }
                  width={20}
                />
                <ContentCol
                  index={index}
                  side="right"
                  content={String(item.orderAmount) + "원"}
                  width={20}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
