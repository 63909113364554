import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// 상태 및 액션 타입 정의
export type StoreState = {
  storeId: number;
  storePort: string;
  storePort2: string;
  storePort3: string;
  storePort4: string;
  storeBaud: string;
  storeBaud2: string;
  storeBaud3: string;
  storeBaud4: string;
  openTime: string;
  closeTime: string;
  storePhone: string;
  businessNumber: string;
  storeAddress: string;
  storeName: string;
  isOnOff: string;
  voiceCnt: number;
  volume:number;
  usbPrintYn1 : string;
  usbPrintYn2 : string;
  usbPrintYn3 : string;
  usbPrintYn4 : string;
};

export type StoreActions = {
  setStoreId: (storeId: number) => void;
  setStorePort: (storePort: string) => void;
  setStorePort2: (storePort: string) => void;
  setStorePort3: (storePort: string) => void;
  setStorePort4: (storePort: string) => void;
  setStoreBaud: (storeBaud: string) => void;
   setStoreBaud2: (storeBaud: string) => void;
    setStoreBaud3: (storeBaud: string) => void;
     setStoreBaud4: (storeBaud: string) => void;
  setOpenTime: (openTime: string) => void;
  setCloseTime: (closeTime: string) => void;
  setStorePhone: (storePhone: string) => void;
  setBusinessNumber: (businessNumber: string) => void;
  setStoreAddress: (storeAddress: string) => void;
  setStoreName: (storeName: string) => void;
  setIsOnOff: (isOnOff: string) => void;
  setVoiceCnt: (voiceCnt: number) => void;
  setVolume : (volume : number)=>void;
  setUsbPrintYn1 : (usbPrintYn:string)=>void;
  setUsbPrintYn2 : (usbPrintYn:string)=>void;
  setUsbPrintYn3 : (usbPrintYn:string)=>void;
  setUsbPrintYn4 : (usbPrintYn:string)=>void;
};

export type StoreStore = StoreState & StoreActions;

// Zustand 스토어 초기화
export const useStoreStore = create<StoreStore>()(
  persist(
    (set) => ({
      storeId: 0,
      storePort: "",
      storeBaud: "",
      storePort2: "",
      storeBaud2: "",
      storePort3: "",
      storeBaud3: "",
      storePort4: "",
      storeBaud4: "",
      openTime: "",
      closeTime: "",
      storePhone: "",
      businessNumber: "",
      storeAddress: "",
      storeName: "",
      isOnOff: "",
      voiceCnt: 1,
      volume : 1,
      usbPrintYn1:"N",
      usbPrintYn2:"N",
      usbPrintYn3:"N",
      usbPrintYn4:"N",
      setStoreId: (storeId: number) => set({ storeId }),
      setStorePort: (storePort: string) => set({ storePort }),
      setStoreBaud: (storeBaud: string) => set({ storeBaud }),
      setStorePort2: (storePort2: string) => set({ storePort2 }),
      setStoreBaud2: (storeBaud2: string) => set({ storeBaud2 }),
      setStorePort3: (storePort3: string) => set({ storePort3 }),
      setStoreBaud3: (storeBaud3: string) => set({ storeBaud3 }),
      setStorePort4: (storePort4: string) => set({ storePort4 }),
      setStoreBaud4: (storeBaud4: string) => set({ storeBaud4 }),
      setOpenTime: (openTime: string) => set({ openTime }),
      setCloseTime: (closeTime: string) => set({ closeTime }),
      setStorePhone: (storePhone: string) => set({ storePhone }),
      setBusinessNumber: (businessNumber: string) => set({ businessNumber }),
      setStoreAddress: (storeAddress: string) => set({ storeAddress }),
      setStoreName: (storeName: string) => set({ storeName }),
      setIsOnOff: (isOnOff: string) => set({ isOnOff }),
      setVoiceCnt: (voiceCnt: number) => set({ voiceCnt }),
      setVolume:(volume : number)=>set({volume}),
      setUsbPrintYn1:(usbPrintYn1:string)=>set({usbPrintYn1}),
      setUsbPrintYn2:(usbPrintYn2:string)=>set({usbPrintYn2}),
      setUsbPrintYn3:(usbPrintYn3:string)=>set({usbPrintYn3}),
      setUsbPrintYn4:(usbPrintYn4:string)=>set({usbPrintYn4}),
    }),
    {
      name: "food-storage", // 저장소에 저장될 아이템 이름 (고유해야 함)
      storage: createJSONStorage(() => sessionStorage), // sessionStorage 사용
    }
  )
);
