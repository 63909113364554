import { request } from "../../request"

export const getOrder = async (storeId : number, startDate:string, endDate:string, category:string,isShow:string, orderType?:string) => {
        const url = `order/getOrderListAjax2?storeId=${storeId}&startDate=${startDate}&endDate=${endDate}&category=${category}&isShow=${isShow}${orderType ? `&orderType=${orderType}` : ''}`;

    try {
        const result = await request.get(url)
        return result.data
    } catch (error) {
        console.log(error)
    }
} 
export const CancelOrder = async (orderId:number,orderStatus:string,upDt:string,cancelInfo:string,isPrint:string) => {
    try {
        const result = await request.post(`order/updateOrderStatusAjax?orderId=${orderId}&orderStatus=20&uptDt=${upDt}&cancelInfo=${cancelInfo}&isPrint=${isPrint}`)
        return result.data
    } catch (error) {
        console.log('error',error)
    }
}

export const ApproveOrder = async (orderId:number,orderStatus:string,uptDt:string,isPrint:string) => {
    try {
        const result = await request.post(`order/updateOrderStatusAjax?orderId=${orderId}&orderStatus=${orderStatus}&uptDt=${uptDt}&isPrint=${isPrint}`)
        return result.data
    } catch (error) {
        console.log("error")
    }
}

export const getOrderStatus = async (storeId:number,orderId:number) => {
    try {
        const result = await request.get(`order/getOrderStatus?storeId=${storeId}&orderId=${orderId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const getOrderList = async(storeId : number, startDate:string, endDate:string, isShow:string) => {
    try {
        const result = await request.get(`order/getOrderListAjax?storeId=${storeId}&startDate=${startDate}&endDate=${endDate}&isShow=${isShow}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const getPickUpTime = async(orderId:number) => {
    try {
        const result = await request.get(`order/getDeliveryPickupTime?orderId=${orderId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}


export const newOrderCheck = async(storeId :  number) => {
    try {
        const result = await request.get(`order/lastOrderTimeAjax2?storeId=${storeId}&timeType=order`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const getOrderDetail =async(orderId:number) => {
    try {
        const result = await request.get(`order/getOrderDetailAjax?orderId=${orderId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const refund = async (orderId:number, uptDt:string) => {
    try {
        const result= await request.post(`/order/updateOrderStatusAjax?orderId=${orderId}&orderStatus=80&uptDt=${uptDt}&isPrint=Y`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const getOrderBarcode = async (orderId:number) => {
    try {
        const result = await request.get(`order/getOrderInfo?orderId=${orderId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}


export const cancelOrder30m = async (orderId:number) => {
    try {
        const result = await request.get(`order/cancelprocess?orderId=${orderId}`)
        return result.data
    } catch (error) {
console.log(error)
    }
}