interface DeviceType {
    storeId : number;
    baudRate : string;
    devicePort : string;

}

import { request } from "../../request"

export const setting = async (storeId:Number , deviceIp : string) => {
    try {
        const result = await request.post(`device/regDeviceAjax?storeId=${storeId}&deviceIp=${deviceIp}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}


export const getSetting = async (storeId : Number) => {
    try {
        const result = await request.get(`device/getDeviceDetailAjax2?storeId=${storeId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const setSetting = async(data) => {
    try {
        const result = await request.post(`device/regDeviceListAjax`,data)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const updateSetting = async(storeId:number, deviceIp : string, devicePort : string, changeDeviceIp:string,soundCount:number) => {
    try {
        const result = await request.put(`device/updateDeviceAjax2?storeId=${storeId}&deviceIp=${deviceIp}&devicePort=${devicePort}&changeDeviceIp=${changeDeviceIp}&soundCount=${soundCount}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}
