import React, { useEffect, useState } from "react";
import { Navigate, redirect, Route, Routes } from "react-router-dom";
import Login from "./page/login/login";
import Main from "./page/main";
import { PrivateRoute } from "./privateRoute";
import { getStoreInfo } from "./@api/service/auth/auth";
import { useStoreStore } from "./store/storeStore";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    storeId,
    setStoreId,
    setStorePort,
    setStoreBaud,
    setStorePort2,
    setStoreBaud2,
    setStorePort3,
    setStoreBaud3,
    setStorePort4,
    setStoreBaud4,

    setOpenTime,
    setCloseTime,
    setStorePhone,
    setBusinessNumber,
    setStoreAddress,
    setStoreName,
    setIsOnOff,
  } = useStoreStore();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false); // 토큰 확인 완료 후 로딩 상태 해제
  }, []);

  const getInfo = async () => {
    try {
      const result = await getStoreInfo();
      console.log("twtwtwtw", result.data.deviceList[0].devicePort);
      setStoreId(result.data.storeId);
      setBusinessNumber(result.data.businessNumber);
      setCloseTime(result.data.closeTime);
      setStorePort(result.data.deviceList[0].devicePort);
      setStorePort2(result.data.deviceList[1].devicePort);
      setStorePort3(result.data.deviceList[2].devicePort);
      setStorePort4(result.data.deviceList[3].devicePort);
      setStoreBaud(result.data.deviceList[0].baudRate);
      setStoreBaud2(result.data.deviceList[1].baudRate);
      setStoreBaud3(result.data.deviceList[2].baudRate);
      setStoreBaud4(result.data.deviceList[3].baudRate);
      setIsOnOff(result.data.isOnOff);
      setOpenTime(result.data.openTime);
      setStoreAddress(result.data.storeAddress);
      setStoreName(result.data.storeName);
      setStorePhone(result.data.storePhone);
      console.log("getinfo", result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (storeId === 0) {
      getInfo();
    }
  }, []);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/main" element={<Main />} />
        <Route path="/" element={<Main />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
