import { ReactNode, useEffect, useState } from "react";
import { OrderType } from "../../../types/orderType";
import { refund } from "../../../@api/service/order/order";
import { render } from "react-thermal-printer";
import { ReceiptStore } from "../receipt/ReceiptStore";
import { onClickPrintHandler } from "../../../utils/func";
import axios from "axios";
import { useStoreStore } from "../../../store/storeStore";
import { Spinner } from "../spinner/spinner";
export const Receipt = ({
  order,
  itemIndex,
  setItemIndex,
  length,
  getOrderListApi,
}: {
  order: OrderType;
  itemIndex: number;
  setItemIndex: (itemIndex: number) => void;
  length: number;
  getOrderListApi: () => void;
}) => {
  if (!order) return null;
  const ReceiptHeader = ({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) => (
    <div className="flex flex-row justify-between ">
      <div className="text-sm opacity-50" style={{ fontFamily: "SUIT-Bold" }}>
        {title}
      </div>
      <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
        {content}
      </div>
    </div>
  );
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const RefundApi = async () => {
    try {
      setLoading(true);
      const result = await refund(
        order.orderId,

        order.uptDt
      );
      alert("환불이 완료되었습니다.");
      getOrderListApi();
      console.log("refund", result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const Title = ({ title }: { title: string }) => (
    <div
      className="pt-4 pb-4 flex"
      style={{ borderBottom: "1px solid #B6B6B6" }}
    >
      <div
        className="text-base"
        style={{ fontFamily: "SUIT-Medium", color: "#2E636E" }}
      >
        {title}
      </div>
    </div>
  );

  // const onClickPrintHandler = async () => {
  //   if ("serial" in navigator) {
  //     try {
  //       let data = await render(ReceiptStore({ order, count }));

  //       // 빈 줄 추가
  //       const paddingLines = 5;
  //       const padding = new Uint8Array(paddingLines).fill(0x0a);

  //       // 데이터 병합
  //       data = mergeUint8Arrays([data, padding]);

  //       // const port = await navigator.serial.requestPort();

  //       // await port.open({ baudRate: 9600 });
  //       const port = await window.navigator?.serial?.requestPort();
  //       if (port.writable === null) {
  //         // ← 최초 실행에만 포트를 연다.
  //         await port.open({ baudRate: 9600 });
  //       }
  //       const writer = port.writable?.getWriter();

  //       if (writer) {
  //         // 영수증 데이터 전송
  //         await writer.write(data);

  //         // 약간의 지연 시간을 추가
  //         await new Promise((resolve) => setTimeout(resolve, 300)); // 100ms 대기

  //         // 커팅 명령어 전송
  //         const cutCommand = new Uint8Array([0x1d, 0x69]); // 커팅 명령어
  //         await writer.write(cutCommand);

  //         writer.releaseLock();
  //       }
  //       await port.close();
  //     } catch (error) {
  //       console.error("Failed to print:", error);
  //     }
  //   } else {
  //     console.warn("This browser does not support the Web Serial API.");
  //   }
  // };

  const Print = async () => {
    console.log("Initiating print...");

    if ("serial" in navigator) {
      console.log("Web Serial API supported");

      let port;

      // 이전에 선택한 포트를 로컬 스토리지에서 가져오기
      const savedPortInfo = localStorage.getItem("selectedPort");
      if (savedPortInfo) {
        const savedPort = JSON.parse(savedPortInfo);
        // getPorts()를 호출하여 포트 목록을 가져오고 await으로 기다립니다.
        const ports = await navigator.serial.getPorts();
        port = ports.find(
          (p) =>
            p.getInfo().usbVendorId === savedPort.usbVendorId &&
            p.getInfo().usbProductId === savedPort.usbProductId
        );
      }

      if (!port) {
        // 사용자가 포트를 선택하도록 요청
        port = await navigator.serial.requestPort();
        console.log("Port selected:", port);

        // 선택한 포트를 로컬 스토리지에 저장
        const portInfo = port.getInfo();
        localStorage.setItem(
          "selectedPort",
          JSON.stringify({
            usbVendorId: portInfo.usbVendorId,
            usbProductId: portInfo.usbProductId,
          })
        );
      } else {
        console.log("Using saved port:", port);
      }

      await port.open({ baudRate: 9600 });

      await onClickPrintHandler("store", order, count, port);

      await port.close();
      console.log("Port closed");
    } else {
      alert(
        "이 브라우저는 Web Serial API를 지원하지 않습니다. Google Chrome 또는 Microsoft Edge에서 시도해 주세요."
      );
    }
  };

  const ContentWrap = ({ children }: { children: ReactNode }) => (
    <div className="flex flex-row justify-between">{children}</div>
  );
  useEffect(() => {
    const totalQty = order.orderItemList.reduce(
      (acc, item) => acc + item.qty,
      0
    );
    setCount(totalQty);
  }, [order]);
  // const printData = async (data) => {
  //   try {
  //     const response = await axios.post("http://localhost:3001/print", {
  //       data,
  //     });
  //     console.log("프린트 결과:", response.data);
  //   } catch (error) {
  //     console.error("프린트 요청 에러:", error.message);
  //   }
  // };

  // 사용 예시

  const { storeBaud, storePort } = useStoreStore();
  const serialOrderPrint = async (
    order,
    port = storePort,
    baudRate = storeBaud,
    cut = true,
    type = "store"
  ) => {
    try {
      const response = await axios.post("http://localhost:5000/print", {
        port: port,
        baudRate: baudRate,
        data: order, // order 정보를 API에 포함
        cut: cut, // 커팅 여부
        type: type,
      });
      console.log(response.data); // 서버 응답 출력
    } catch (error) {
      console.error("Error printing:", error);
    }
  };

  const usbOrderPrint = async (order, type) => {
    const data = {
      order: order,
      type: type,
    };
    try {
      const response = await axios.post("http://localhost:5001/print", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Print success:", response.data);
    } catch (error) {
      console.error("Error printing order:", error);
    }
  };

  return (
    <div className="flex flex-col gap-4 flex-1">
      {loading && <Spinner />}
      <div
        className="flex flex-col gap-4 pr-4"
        style={{ overflowY: "auto", height: "80%", maxHeight: "600px" }}
      >
        {/* 버튼 */}
        {["94", "90", "91", "92", "93", "95", "96"].includes(
          order.orderStatus
        ) && (
          <div className="flex flex-row">
            <button
              className="flex justify-center items-center p-2 rounded-sm text-white"
              style={{
                backgroundColor: "#EC745A",
                fontFamily: "SUIT-Medium",
              }}
              onClick={() => {
                RefundApi();
              }}
            >
              환불요청
            </button>
          </div>
        )}
        <div className="flex  justify-end items-end">
          <button
            className="w-fit flex justify-center items-center text-base py-3 px-3"
            style={{ border: "1px solid #000000", fontFamily: "SUIT-Medium" }}
            onClick={async () => {
              // Print();
              // handlePrint();
              if (storePort === "USB") {
                usbOrderPrint(order, "store");
              } else {
                serialOrderPrint(order);
              }
            }}
          >
            주문서 재출력
          </button>
        </div>
        {/* 영수증 header */}
        <div className="flex flex-col">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col gap-2">
                <div className="text-xl" style={{ fontFamily: "SUIT-Bold" }}>
                  {order?.hotelName}
                </div>
                <div
                  className="text-xl opacity-30"
                  style={{ fontFamily: "SUIT-Bold" }}
                >
                  {order?.hotelAddress}
                </div>
              </div>
              <div
                className="flex justify-center items-center h-fit p-2"
                style={{ backgroundColor: "#84A2DD" }}
              >
                <div
                  className="text-white text-base"
                  style={{ fontFamily: "SUIT-ExtraBold" }}
                >
                  {order?.storeName}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <ReceiptHeader title="주문자명" content={order?.userName} />
              <ReceiptHeader
                title="주문자명 휴대폰 번호"
                content={order?.userPhone}
              />
              <ReceiptHeader title="주문시각" content={order?.orderDate} />
              <ReceiptHeader title="메모" content={order?.orderMemo} />
            </div>
          </div>
        </div>
        {/* 주문 목록 */}
        <div className="flex flex-col gap-4">
          <Title title="주문목록" />
          <div className="flex flex-col gap-2">
            {order?.orderItemList.map((item, index) => {
              return (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between">
                    <div
                      className="text-sm"
                      style={{ fontFamily: "SUIT-Bold" }}
                    >
                      {item.itemName}
                    </div>
                    <div className="flex flex-row gap-8">
                      <div
                        className="text-sm "
                        style={{ fontFamily: "SUIT-Bold" }}
                      >
                        {item.qty}
                      </div>
                      <div
                        className="text-sm w-[50px]"
                        style={{ fontFamily: "SUIT-Bold" }}
                      >
                        {item.amount}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* 결제 내역 */}
        <div className="flex flex-col gap-4 ">
          <Title title="결제내역" />
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                결제 정보
              </div>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                {order.cardName} | {order.cardNumber} {order.cretDt}
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                결제금액
              </div>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                {order.payAmount}
              </div>
            </div>
          </div>
        </div>
        {/* 히스토리 */}
        <div className="flex flex-col gap-4 ">
          <Title title="히스토리" />
          <div className="flex flex-col gap-2">
            <ContentWrap>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                {order.orderDate}
              </div>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                주문
              </div>
            </ContentWrap>
            <ContentWrap>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                {order.deliveryStartDate}
              </div>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                배달 시작
              </div>
            </ContentWrap>
            <ContentWrap>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                {order.deliveryEndDate}
              </div>
              <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
                완료
              </div>
            </ContentWrap>
            {order.orderStatus === "80" && (
              <ContentWrap>
                <div
                  className="text-sm text-red-500"
                  style={{ fontFamily: "SUIT-Bold" }}
                >
                  {order.cancelDate}
                </div>
                <div className="text-sm " style={{ fontFamily: "SUIT-Bold" }}>
                  취소[환불]
                </div>
              </ContentWrap>
            )}
          </div>
        </div>
        {/* 요청사항 */}
        <div className="flex flex-col gap-4">
          <Title title="요청사항" />
          <div
            className="p-4 flex rounded-lg"
            style={{ backgroundColor: "#D1D1D11A" }}
          >
            <div
              className="text-sm opacity-50"
              style={{ fontFamily: "SUIT-Bold" }}
            >
              {order.deliveryMemo}
            </div>
          </div>
        </div>
      </div>

      {/* 버튼 */}
      <div className="flex flex-row gap-4">
        <button
          className="w-1/2 flex justify-center items-center text-2xl pt-[22px] pb-[22px]"
          onClick={() => {
            if (itemIndex > 0) {
              setItemIndex(itemIndex - 1);
            }
          }}
          style={{ fontFamily: "SUIT-Bold", border: "1px solid #2C3832" }}
        >
          이전
        </button>
        <button
          className="w-1/2 flex justify-center items-center text-2xl pt-[22px] pb-[22px]"
          style={{ fontFamily: "SUIT-Bold", border: "1px solid #2C3832" }}
          onClick={() => {
            if (itemIndex < length - 1) {
              setItemIndex(itemIndex + 1);
            }
          }}
        >
          다음
        </button>
      </div>
    </div>
  );
};
